import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/pravne_oznamenie/" className="sel">
              {" "}
              &gt; Právne oznámenie
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">Právne oznámenie</h1>
            <div className="wysiwyg">
              <p>
                Všetok obsah týchto stránok vlastní alebo prevádzkuje spoločnosť
                JNTL Consumer Health (Slovakia) s.r.o., a celosvetovo ho chráni autorské
                právo. Obsah môžete sťahovať iba pre svoje osobné nekomerčné
                využitie, ale nie je dovolené tento obsah pozmeňovať alebo ďalej
                reprodukovať. Obsah nesmie byť kopírovaný ani využívaný inak ako
                v súlade s vyššie uvedeným účelom.
                <br />
                <br />
                Vlastník tejto stránky vynaloží primerané úsilie, aby do týchto
                internetových stránok začlenil aktuálne a presné informácie, ale
                neposkytuje žiadne záruky alebo uistenia akéhokoľvek druhu
                týkajúce sa presnosti, aktuálnosti alebo kompletnosti
                poskytovaných informácií.
                <br />
                <br />
                Vlastník tejto stránky nenesie zodpovednosť za žiadnu škodu
                alebo ujmu spôsobenú v dôsledku vášho prístupu alebo nemožnosti
                prístupu k týmto internetovým stránkam, alebo vášho spoľahnutia
                sa na akékoľvek informácie uvedené na týchto internetových
                stránkach.
                <br />
                <br />
                Táto stránka je určená pre adresátov zo Slovenskej republiky.
                <br />
                <br />
                Tieto internetové stránky môžu poskytovať odkazy alebo
                referencie na iné stránky, ale ich vlastník nenesie žiadnu
                zodpovednosť za obsah takých iných stránok a nemá povinnosť
                nahradiť akúkoľvek škodu alebo ujmu vyplývajúcu z tohto obsahu.
                Akékoľvek odkazy na iné stránky sú poskytované výhradne pre
                pohodlie užívateľov týchto internetových stránok.
                <br />
                <br />
                Ochranné známky, servisné známky, obchodné názvy, obchodný
                dizajn a výrobky uvedené na týchto internetových stránkach sú
                chránené v Slovenskej republike a medzinárodne. Nič z vyššie
                uvedeného nesmie byť použité bez predchádzajúceho písomného
                súhlasu vlastníka tejto stránky, s výnimkou použitia pre
                označenie výrobkov alebo služieb spoločnosti.
                <br />
                <br />
                Akékoľvek informácie či údaje týkajúce sa osôb obsiahnuté v
                elektronickej komunikácii s touto internetovou stránkou
                podliehajú{" "}
                <a href="/zasady_ochrany_osobnych_udajov/" target="_blank">
                  Zásadám ochrany osobných údajov
                </a>
                &nbsp; pre túto stránku. Vlastník tejto stránky bude s
                informáciami či údajmi zaobchádzať v súlade s príslušnými
                právnymi predpismi a vždy tak, aby zabránil vzniku akejkoľvek
                ujmy u osobe, ktorej sa takéto informácie alebo údaje týkajú.
                <br />
                <br />
                Odosielateľ akejkoľvek komunikácie na tieto stránky alebo inak
                vlastníkovi tejto stránky je zodpovedný za obsah a informácie v
                tejto komunikácii obsiahnuté, vrátane ich pravdivosti a
                presnosti.
                <br />
                <br />
                &nbsp;
              </p>
            </div>
          </div>
          <Footer></Footer>{" "}
          
        </div>
      </div>
    );
  }
}

export default Page;
